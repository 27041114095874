<template>
    <div>
        <div class="pay-sum">
            <div :class="[item.type]" class="sum-item" v-for="item in chartList[0]['fields']" :key="item.value">
                <!--                <div class="icon">-->
                <!--                </div>-->
                <div class="title">
                    今日{{ item.label }}:
                </div>
                <div class="sum">
                    <countTo :startVal='0' :endVal="today[item.value]||0" :duration='2000'></countTo>
                </div>
            </div>
        </div>
        <div class="pay-sum">
            <div :class="[item.type]" class="sum-item danger" v-for="item in chartList[1]['fields']" :key="item.value">
                <!--                <div class="icon">-->
                <!--                </div>-->
                <div class="title">
                    今日{{ item.label }}:
                </div>
                <div class="sum">
                    <countTo :startVal='0' :endVal="today[item.value]||0" :duration='2000'></countTo>
                </div>
            </div>
        </div>
        <div class="chart" v-for="item in chartList" :key="item.name">
            <div :ref="item.name" class="chart-item">

            </div>
        </div>
    </div>
</template>
<script>
import countTo from "vue-count-to";
import *  as echarts from 'echarts';
import '@/assets/js/echarts-theme/walden';
export default {
    name: "app-sum",
    components: {
        countTo
    },
    created() {

    },
    mounted() {
        this.getSum();
    },
    data: function () {
        return {
            baseUrl: '/bigmap/pay/sum',
            baseProductUrl: '/bigmap/product/index',
            chartList: [
                {
                    name: 'sum',
                    title: '每日收益情况',
                    type: 'base',
                    fields: [{label: '总收益', value: 's'}, {label: '安卓收益', value: 'a_s'}, {
                        label: 'IOS收益',
                        value: 'i_s'
                    }, {label: 'PC收益', value: 'pc_s'}]
                },
                {
                    name: 'refund',
                    type: 'base',
                    title: '每日退款情况',
                    fields: [{label: '总退款', value: 'r'}, {label: '安卓退款', value: 'a_r'}, {
                        label: 'IOS退款',
                        value: 'i_r'
                    }, {label: 'PC退款', value: 'pc_s'}]
                },
                {
                    name: 'android_sum',
                    title: '安卓每平台每日总收益情况',
                    type: 'base',
                    fields: [
                        {label: '微信支付', value: 'a_w_p_s'}, {label: '支付宝支付', value: 'a_a_p_s'}]
                },
                {
                    name: 'ios_sum',
                    title: 'IOS每日每平台总收益情况',
                    type: 'base',
                    fields: [{label: 'IOS App Store', value: 'i_i_p_s'}]
                },
                {
                    name: 'pc_sum',
                    title: 'PC平台每日收益情况',
                    type: 'base',
                    fields: [
                        {label: 'PC支付', value: 'pc_pc_p_s'}]
                },
                {
                    name: 'android_order',
                    title: '安卓每日订单生成与支付情况',
                    type: 'base',
                    fields: [{label: '订单生成', value: 'a_c'}, {label: '订单支付', value: 'a_p'}]
                },
                {
                    name: 'ios_order',
                    title: 'IOS每日订单生成与付款情况对比',
                    type: 'base',
                    fields: [{label: '订单生成', value: 'i_c'}, {label: '订单支付', value: 'i_p'}]
                },
                {
                    name: 'pc_order',
                    title: 'PC每日订单生成与付款情况对比',
                    type: 'base',
                    fields: [{label: '订单生成', value: 'pc_c'}, {label: '订单支付', value: 'pc_p'}]
                },
                {
                    name: 'android_product_sum',
                    title: '安卓产品每日收益',
                    type: 'calc',
                    prefix: 'a_s_',
                    fields: []
                },
                {
                    name: 'android_product_order',
                    type: 'calc',
                    title: '安卓产品每日订单量',
                    prefix: 'a_p_',
                    fields: []
                },
                {
                    name: 'ios_product_sum',
                    type: 'calc',
                    title: 'IOS产品每日收益',
                    prefix: 'i_s_',
                    fields: []
                },
                {
                    name: 'iso_product_order',
                    type: 'calc',
                    prefix: 'i_p_',
                    title: 'IOS产品每日订单量',
                    fields: []
                },
                {
                    name: 'pc_product_sum',
                    type: 'calc',
                    title: 'PC产品每日收益',
                    prefix: 'pc_s_',
                    fields: []
                },
                {
                    name: 'pc_product_order',
                    type: 'calc',
                    title: 'PC产品每日订单量',
                    prefix: 'pc_p_',
                    fields: []
                },
            ],
            chartData: [],
            today: {},
            product_list: {},
        }
    },
    methods: {
        getSum() {
            let product = this.$app.get(this.baseProductUrl);
            let sumRequest = this.$app.get(this.baseUrl);
            Promise.all([product, sumRequest]).then(all => {
                all[0].data.map(v => {
                    this.product_list[v.product_id] = v;
                });
                let data = all[1];
                this.today = data.data.today || {};
                let total = data.data.sum.reverse().map(v => {
                    return {
                        time: v.time,
                        pay: v.pay
                    }
                });
                total.push({time: new Date().getTime() / 1000, pay: this.today});
                this.chartData = total;
                this.createChart();
                // ['sum-chart','refund-chart','android-order','all_android_order','all_ios_order'].map(v=>{
                //     this.createChart(v,total);
                // });
            });
        },
        createChart() {
            this.chartList.map(v => {
                let chart, fields, option = {
                    // animation:true,
                    // animationDuration:10000,
                    legend: {
                        top: 30,
                        data: []
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    title: {
                        x: "center",
                        y: 0,
                        text: '每日收益情况',
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid: {
                        left: '30px',
                        // top:'40px',
                        bottom: '20px',
                        right: '10px'
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: [820, 932, 901, 934, 1290, 1330, 1320],
                            type: 'line',
                            name: '',
                            smooth: true
                        }
                    ]
                };
                if (!this.$refs[v.name][0]) {
                    return;
                }
                chart = echarts.init(this.$refs[v.name][0], 'walden');
                option.title.text = v.title;
                option.xAxis.data = this.chartData.map(v => {
                    let date = new Date(v.time * 1000);
                    return [date.getMonth() + 1, date.getDate()].join('-');
                });
                switch (v.type) {
                    case 'base':
                        option.legend.data = v.fields.map(v => v.label);
                        option.series = v.fields.map(v => {
                            return {
                                data: this.chartData.map(vv => {
                                    return vv.pay[v.value] || 0;
                                }),
                                // markPoint: {
                                //     data: [{
                                //         type: 'max',
                                //         name: '最大值'
                                //     }]
                                // },
                                name: v.label,
                                type: 'line',
                                smooth: true,
                            }
                        });
                        break;
                    case 'calc':
                        fields = this.getProductMapping(v.prefix);
                        // console.log(fields);
                        option.legend.data = fields.map(v => v.label);
                        option.series = fields.map(v => {
                            return {
                                data: this.chartData.map(vv => {
                                    return vv.pay[v.value] || 0;
                                }),
                                name: v.label,
                                type: 'line',
                                smooth: true,
                            }
                        });
                        break;
                }
                if (!chart) return;
                // option.series.push({
                //     data: [{
                //         type: 'max',
                //         name: '最大值'
                //     },
                //         {
                //             type: 'min',
                //             name: '最小值'
                //         }
                //     ]
                // }
                chart.setOption(option);
            });
        },
        getProductMapping(prefix) {
            let allProduct = {};
            this.chartData.map(v => {
                Object.keys(v.pay).map(vv => {
                    if (vv.indexOf(prefix) === 0) {
                        let productId = vv.split('_').pop();
                        allProduct[vv] = this.product_list[productId] && this.product_list[productId]['title'] || '未知产品';
                    }
                });
            });
            let mapping = [];
            for (let k in allProduct) {
                mapping.push({label: allProduct[k], value: k});
            }
            return mapping;
        }
    }
}
</script>

<style lang="less" scoped>
.pay-sum {
    display: flex;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 10px;

    .sum-item {
        flex: 1;
        margin: 10px;
        display: flex;
        height: 80px;
        border-radius: 5px;
        color: #fff;
        background-color: #45bbe0;

        &.danger {
            background-color: rgba(122, 54, 60, 0.85);
        }

        .title {
            flex-shrink: 0;
            width: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        .sum {
            display: flex;
            align-items: center;
            height: 100%;
            padding-left: 5px;
            font-weight: bold;
            justify-content: center;
            font-size: 18px;
        }
    }
}

.chart {
    height: 280px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 10px;

    .chart-item {

        height: 100%;
    }
}
</style>
